import React from "react";
import IntellirootContainer from "../components/layout-components/Container";
import Navbar from "../components/layout-components/Navbar";
import Footer from "../components/layout-components/Footer";
import SignInSide from "../components/auth/login/SignInSide";

const Login = () => {
  return (
    <React.Fragment>
      <Navbar />
      <IntellirootContainer>
        <SignInSide />
      </IntellirootContainer>
      <Footer />
    </React.Fragment>
  );
};

export default Login;
