import * as React from "react";
import { styled } from "@mui/system";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@mui/material";
import ModuleModal from "./Modal";

const CardMain = styled(Card)(({ theme }) => ({
  flexBasis: "calc(50% - 1.5rem)",
  // height: "250px",
  cursor: "pointer",
  marginBottom: "1rem",
  backgroundColor: "#ffffff",
  backgroundImage: "linear-gradient(315deg, #DEE4EA 0%, #F9FCFF 74%)",
}));

export default function LiveCard(props) {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <ModuleModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        title={props.title}
        subtitle={props.subtitle}
        description={props.description}
      />
      <CardMain
        onClick={() => {
          setOpen(true);
        }}
      >
        <CardContent>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            sx={{
              fontWeight: "600",
              color: "#000000",
            }}
          >
            {props.title ? props.title : ""}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "0.8rem",
              // mt: "0.5rem",
              color: "#757575",
            }}
          >
            {props.subtitle ? props.subtitle : ""}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: "1rem", mb: "0.4rem", color: "#000000", height: "100px" }}
          >
            {props.description
              ? props.description?.substring(0, 220) + "..."
              : ""}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">View Syllabus</Button>
        </CardActions>
      </CardMain>
    </React.Fragment>
  );
}
