import React from "react";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import LiveCard from "./advanced-malware-live/Card";
import DemandCard from "./advanced-malware-demand/Card";
import BookCard from "./book/Card";
import Testimonial from "../home/testimonials/Testimonial";

const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const CardsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "row",
  gap: "1.5rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const HeadingText = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 600,
  margin: "3rem 0rem 1.5rem 0rem",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    fontSize: "1.2rem",
  },
}));

function Courses() {
  return (
    <React.Fragment>
      <MainContainer>
        <div>
          <HeadingText>Courses</HeadingText>
          <CardsContainer>
            <DemandCard
              title="Malware Reverse Engineering: Beginner to Advanced (On-Demand)"
              subtitle="By Abhijit Mohanta"
              description="This training delves into the intricacies of modern malware, which are becoming increasingly sophisticated with tactics such as credential theft, ransomware encryption, credit card data theft from POS devices, and evasion techniques for EDR and antivirus systems. Traditional static and dynamic analyses, as well as memory forensics, often fall short in identifying these complex techniques. Reverse engineering is essential to uncovering malware's inner workings at the code level. The course elevates students' skills beyond traditional malware analysis, empowering them to delve deeper into malware and contribute to the enhancement of anti-malware products like EDR and Antiviruses."
            />
            <LiveCard
              title="Advanced Malware Reverse Engineering (Live)"
              subtitle="By Abhijit Mohanta"
              description="The training walks you through various diversities in windows malware which include malware coded in various programming languages, armored with various packers and armoring tricks. The training covers various techniques employed by windows malware which is not limited to code injection, process hollowing, API, rootkits. Students would learn to reverse engineer various kinds of malware families which include Ransomware, Stealers, Remote Access Trojans(RATS) written in various programming languages from .net, C, C++ etc. The training would teach students to effectively use tools like NSA Ghidra, IDA pro, ollydbg, process hacker and man."
            />
          </CardsContainer>
        </div>
        <BookCard title="Reference Books" />
      </MainContainer>
      <Testimonial />
    </React.Fragment>
  );
}

export default Courses;
