import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogContent,
  DialogActions,
  // IconButton,
  Typography,
  Link,
} from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
import data from "./CourseData.js";

const ModalDialog = styled(Dialog)(({ theme }) => ({
  // "& .MuiDialogContent-root": {
  //   padding: 0,
  // },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const TextContainer = styled("div")(({ theme }) => ({
  padding: "0rem 2rem 0rem 1rem",
}));

const LinkedIn = styled(Link)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#fff" : "#000",
  "&:hover": {
    color: "#d32525",
  },
}));

const features = [
  {
    title: "35+ hours of recorded videos",
  },
  {
    title:
      "5 hours of prerequisite videos included which includes Assembly language",
  },
  {
    title: "80% labs",
  },
  {
    title: "50+ concepts",
  },
  {
    title: "50+ sample including malware and simulated samples",
  },
  {
    title: "50 + lab exercises",
  },
  {
    title: "Easier and quicker learning with",
    sub: [
      {
        title: "Animated videos to demonstrate concepts",
      },
      {
        title: "Custom samples exhibiting a single technique",
      },
    ],
  },
];

const takeAway = [
  "Focussed on Reverse Engineering considering the diversity in today’s malwares",
  "Learn reading disassembly to identify various code components",
  "Effectively use free tools Ghidra, IDA freeware, Ollydbg, x64Dbg and effectively combine them for better results",
  "Students would learn to identify and analyze important functionalities in Ransomware, RAT’s stealers and other malware families",
  "Reverse Engineer malware .net, golang, C++ compiled binaries",
  "Learn to identify various techniques in malware disassembly which includes Cryptography, Lateral movement, Rootkits, API hooks, code injection",
  "Variety of unpacking techniques",
  "Learn to debug exe’s. Dll’s , windows services, COM objects, kernel malwares",
];

export default function ModuleModal(props) {
  return (
    <ModalDialog
      aria-labelledby="customized-dialog-title"
      open={props.open}
      onClose={props.onClose}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogContent>
        {/* <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
            zIndex: 9999,
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <TextContainer>
          <Typography variant="h4" component="h4">
            {props.title ? props.title : ""}
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontWeight: 600,
            }}
          >
            Bonus Topic Included: Building Blocks of Malware Detection
            Technologies (Internals of Antivirus, EDR's, Sandboxes)
          </Typography>

          <Typography variant="h6" component="h6">
            Instructor:
            <LinkedIn
              underline="none"
              target="_blank"
              href="https://www.linkedin.com/in/abhijitmohanta/"
            >
              {" "}
              Abhijit Mohanta
            </LinkedIn>
          </Typography>
          {/* <Typography variant="h6" component="h6">
            Price: 30,000 INR / 375 USD (excluding taxes)
          </Typography> */}
          <Typography variant="h6" component="h6">
            Course Duration: 35+ hours of Video content with 85% of lab
            exercises
          </Typography>
          {/* <Typography variant="body1" component="span">
            {props.description ? props.description : ""}
          </Typography> */}

          <Typography
            variant="h5"
            component="h5"
            sx={{
              mt: 3,
              mb: 1,
              fontWeight: "bold",
            }}
          >
            About Instructor
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              mb: 1,
            }}
          >
            Abhijit Mohanta is CTO of Intelliroot. He is author of books{" "}
            <Link
              href="https://www.amazon.com/Malware-Analysis-Detection-Engineering-Comprehensive/dp/1484261925"
              target="_blank"
            >
              "Malware Analysis and Detection Engineering"
            </Link>{" "}
            from Apress Springer publications and{" "}
            <Link
              href="https://www.amazon.com/Preventing-Ransomware-Understand-remediate-ransomware/dp/1788620607"
              target="_blank"
            >
              "Preventing Ransomware"
            </Link>{" "}
            from Packtpub. He has worked on malware analysis and detection
            technologies for 17 years. He has worked as a researcher for
            <b> Mcafee, Symantec, Uptycs, Juniper Networks</b> and has worked on
            development of <b>Antivirus , EDR</b> and <b>Sandboxes</b>. He has 6
            patents related to malware detection and has spoken in various
            security conferences.
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            sx={{
              mt: 3,
              mb: 1,
              fontWeight: "bold",
            }}
          >
            Detailed Course Description
          </Typography>

          <Typography
            variant="body1"
            component="p"
            sx={{
              mb: 1,
            }}
          >
            This training delves into the intricacies of modern malware, which
            are becoming increasingly sophisticated with tactics such as
            credential theft, ransomware encryption, credit card data theft from
            POS devices, and evasion techniques for EDR and antivirus systems.
            Traditional static and dynamic analyses, as well as memory
            forensics, often fall short in identifying these complex techniques.
            Reverse engineering is essential to uncovering malware's inner
            workings at the code level. The course elevates students' skills
            beyond traditional malware analysis, empowering them to delve deeper
            into malware and contribute to the enhancement of anti-malware
            products like EDR and Antiviruses.
          </Typography>
          <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
            Focusing on reverse engineering malware on the Windows platform,
            this training caters to both beginners and experienced
            professionals. It covers a wide range of topics, including various
            programming languages used in malware, diverse packing and armoring
            techniques, and malicious tactics such as code injection, process
            hollowing, rootkits, building blocks of malware detection.
          </Typography>
          <Typography variant="body1" component="p" sx={{ mb: 1 }}>
            Students will learn to reverse engineer different malware families,
            including Ransomware, Stealers, and Remote Access Trojans (RATS),
            written in languages like .NET, C, and C++. Tools such as NSA
            Ghidra, IDA Pro, OllyDbg, Process Hacker, and more will be utilized
            effectively.
          </Typography>
          <Typography variant="body1" component="p" sx={{ mb: 1 }}>
            To facilitate learning, the training incorporates simulated and real
            malware samples. Complex concepts like code injection and API
            hooking are explained using animations for better visualization,
            while numerous lab exercises allow students to apply theory to
            real-world scenarios. The course also covers prerequisites for
            reverse engineering, ensuring that even those unfamiliar with
            assembly language can grasp the concepts effectively.
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            sx={{
              mt: 3,
              mb: 1,
              fontWeight: "bold",
            }}
          >
            Detailed Syllabus
          </Typography>

          {data?.map((item, index) => (
            <div key={index}>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontWeight: "bold",
                }}
              >
                {item.main ? item.main : ""}
              </Typography>
              {item?.syllabus?.map((main, key) => (
                <div key={key}>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{
                      mt: 3,
                      mb: 1,
                      fontWeight: "bold",
                    }}
                  >
                    {main.title ? main.title : ""}
                  </Typography>
                  <Typography variant="body1" component="span">
                    {main.description ? main.description : ""}
                  </Typography>
                  <ul>
                    {main?.sub?.map((sub, k) => (
                      <li key={k}>{sub.title ? sub.title : ""}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}

          <Typography
            variant="h5"
            component="h5"
            sx={{
              mt: 5,
              mb: 1,
              fontWeight: "bold",
            }}
          >
            Features
          </Typography>
          <ul>
            {features.map((feature, index) => (
              <div key={index}>
                <li>{feature.title}</li>
                {feature?.sub?.map((sub, k) => (
                  <ul key={k}>
                    <li>{sub.title ? sub.title : ""}</li>
                  </ul>
                ))}
              </div>
            ))}
          </ul>

          <Typography
            variant="h5"
            component="h5"
            sx={{
              mt: 5,
              mb: 1,
              fontWeight: "bold",
            }}
          >
            Key Takeaways
          </Typography>
          <ul>
            {takeAway.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </TextContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          sx={{
            textTransform: "none",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </ModalDialog>
  );
}
