import React from "react";
import IntellirootContainer from "../components/layout-components/Container";
import Navbar from "../components/layout-components/Navbar";
import Footer from "../components/layout-components/Footer";
// import BannerText from "../components/home/BannerText";
// import Books from "../components/home/Books";
// import SwipeableVideo from "../components/home/SwipeableVideo";
import SectionOne from "../components/training/SectionOne";

const Home = () => {
  return (
    <React.Fragment>
      <Navbar />
      <IntellirootContainer>
        {/* <BannerText />
        <Books /> */}
        {/* <SwipeableVideo /> */}
        <SectionOne />
      </IntellirootContainer>
      <Footer />
    </React.Fragment>
  );
};

export default Home;
