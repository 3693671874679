import * as React from "react";
import { styled } from "@mui/system";
import { Typography, Link } from "@mui/material";

const CardMain = styled("div")(({ theme }) => ({
  width: "100%",
  marginBottom: "1rem",
  border: theme.palette.mode === "dark" ? "1px solid #fff" : "1px solid #000",
  borderRadius: "5px",
  marginTop: "2.9rem",
  padding: "1rem 0.8rem",
}));

const HeadingText = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 600,
  margin: "0 0 2rem 0",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
  },
}));

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "2rem",
  marginBottom: "1.6rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "0 2rem",
  },
}));

const Image1 = styled("img")(({ theme }) => ({
  width: 145,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Image2 = styled("img")(({ theme }) => ({
  width: 168,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export default function DemandCard(props) {
  return (
    <React.Fragment>
      <CardMain>
        <HeadingText>{props.title ? props.title : ""}</HeadingText>
        <Container>
          <Link
            href="https://www.amazon.in/Malware-Analysis-Detection-Engineering-Comprehensive/dp/1484261925"
            underline="none"
            target="_blank"
          >
            <Image1
              src="/images/books/malware_analysis_and_detection_engineering.jpg"
              alt="malware_analysis_and_detection_engineering"
              width={130}
              loading="eager"
              referrerPolicy="no-referrer"
            />
          </Link>
          <Link
            href="https://www.amazon.in/Preventing-Ransomware-Understand-remediate-ransomware/dp/1788620607"
            underline="none"
            target="_blank"
          >
            <Image2
              src="/images/books/preventing_ransomware.jpg"
              alt="preventing_ransomware"
              width={150}
              loading="eager"
              referrerPolicy="no-referrer"
            />
          </Link>
        </Container>
      </CardMain>
    </React.Fragment>
  );
}
