import * as React from "react";
import { styled as muiStyled } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";

const BootstrapDialog = muiStyled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "2rem",
  },
}));

const Image = muiStyled(Avatar)(({ theme }) => ({
  width: "3.5rem",
  height: "3.5rem",
}));

const ImageUserContainer = muiStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
}));

const LinkedIn = muiStyled(Link)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#fff" : "#000",
  "&:hover": {
    color: "#d32525",
  },
}));

export default function TestimonialModal({
  open,
  onClose,
  name,
  location,
  review,
  image,
  linkedin,
}) {
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Testimonial
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography variant="body1">
            {review?.split("\n")?.map((item, index) => (
              <React.Fragment key={index}>
                {item.trim()}
                <br />
              </React.Fragment>
            ))}
          </Typography>

          <ImageUserContainer>
            <Image src={image} />
            <div>
              <LinkedIn underline="none" target="_blank" href={linkedin}>
                <Typography variant="body2">{name}</Typography>
              </LinkedIn>
              <Typography
                variant="body2"
                sx={{
                  color: "#757575",
                }}
              >
                {location}
              </Typography>
            </div>
          </ImageUserContainer>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
