import React from "react";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import ProtectedRoute from "./routes/ProtectedRoute";
import Home from "./pages/Home";
import Login from "./pages/Login";
import FourOFour from "./pages/404";
// import SignUp from "./pages/SignUp";
// import ResetPassword from "./pages/ResetPassword";
// import Training from "./pages/Training";

function App() {
  //remove cookie
  const removeCookies = () => {
    Cookies.remove("a7de0d3d", {
      path: "/",
      // domain: ".invincione.in",
      expires: new Date(0),
    });
    window.location.href = "/";
  };

  // Decrypt Cookies
  const decryptData = (data) => {
    try {
      if (data !== undefined) {
        const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_CK_KEY);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
      }
    } catch (error) {
      if (error.message === "Malformed UTF-8 data") {
        removeCookies();
        return null;
      } else {
        return null;
      }
    }
  };

  // Get Cookies
  const encryptedUserData = Cookies.get("a7de0d3d");
  const cookies = decryptData(encryptedUserData);

  React.useEffect(() => {
    if (cookies?.token?.length > 0) {
      if (window.location.pathname === "/login") {
        window.location.href = "/";
      }
    }
  }, [cookies]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />

        <Route path="*" element={<FourOFour />} />
        {/* <Route path="/sign-up" element={<SignUp />} /> */}
        {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
        {/* <Route
          path="/courses"
          element={
            <ProtectedRoute
              isAuthenticated={cookies?.token?.length > 0 ? true : false}
              redirectTo="/login"
            >
              <Training />
            </ProtectedRoute>
          }
        /> */}
        {/* <Route path="/courses" element={<Training />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
