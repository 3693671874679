import React from "react";
import { getCookies, removeCookies } from "../../utils/Cookie";
import { useTheme } from "@mui/material";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Tooltip,
  Avatar,
  Container,
  MenuItem,
  Link,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ToggleButton from "./ToggleButton";

function Navbar() {
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isGlassy, setIsGlassy] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 0 && !isGlassy) {
        setIsGlassy(true);
      } else if (scrollY === 0 && isGlassy) {
        setIsGlassy(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isGlassy]);

  const handleLogout = () => {
    removeCookies();
  };

  const cookies = getCookies();

  const pages = [
    {
      url: "/",
      name: "Courses",
      default: true,
    },
    // {
    //   url: "https://intelliroot.com/about",
    //   name: "About Us",
    //   default: true,
    // },
    // {
    //   url: "/courses",
    //   name: "Courses",
    //   // default: cookies?.token?.length > 0 ? true : false,
    //   default: true,
    // },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: isGlassy
          ? theme.palette.mode === "dark"
            ? "rgba(0, 0, 0, 0.5)"
            : "rgba(255, 255, 255, 0.5)"
          : theme.palette.mode === "dark"
          ? "transparent"
          : "transparent",
        backdropFilter: isGlassy ? "saturate(180%) blur(5px);" : "none",
        boxShadow: isGlassy ? "inset 0 -1px 0 0 rgba(0,0,0,.1);" : "none",
        transition:
          "background-color 0.5s ease-in-out, backdrop-filter 0.5s ease-in-out",
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          sx={{
            margin: "0.2rem 0 0.2rem 0",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
            },
          }}
          variant="dense"
          disableGutters
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <a href="/">
              {theme.palette.mode === "dark" ? (
                <img
                  src="/logo-dark.svg"
                  alt="logo"
                  height={42}
                  loading="eager"
                  referrerPolicy="no-referrer"
                  style={{
                    marginTop: "0.3rem",
                  }}
                />
              ) : (
                <img
                  src="/logo-light.svg"
                  alt="logo"
                  height={42}
                  loading="eager"
                  referrerPolicy="no-referrer"
                  style={{
                    marginTop: "0.3rem",
                  }}
                />
              )}
            </a>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon
                sx={{
                  color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <div key={page.name}>
                  {page.default && (
                    <MenuItem
                      onClick={() => {
                        handleCloseNavMenu();
                        window.location.href = page.url;
                      }}
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  )}
                </div>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              justifyContent: "center",
              mr: 2,
            }}
          >
            <a href="/">
              {theme.palette.mode === "dark" ? (
                <img
                  src="/logo-dark.svg"
                  alt="logo"
                  width={140}
                  height={50}
                  loading="eager"
                  referrerPolicy="no-referrer"
                />
              ) : (
                <img
                  src="/logo-light.svg"
                  alt="logo"
                  width={140}
                  height={50}
                  loading="eager"
                  referrerPolicy="no-referrer"
                />
              )}
            </a>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: 4,
            }}
          >
            {pages.map((page) => (
              <span key={page.name}>
                {page.default && (
                  <Link
                    href={page.url}
                    underline="none"
                    color="#666"
                    sx={{
                      fontSize: "0.875rem",
                      lineHeight: "1rem",
                      fontWeight: 550,
                      "&:hover": {
                        color: "#d32525",
                      },
                    }}
                  >
                    {page.name}
                  </Link>
                )}
              </span>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <ToggleButton />
            {cookies?.token?.length > 0 ? (
              <span>
                <Tooltip title="Account">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      sx={{
                        width: 34,
                        height: 34,
                        bgcolor: "#d32525",
                        color: "#ffffff",
                      }}
                    >
                      {cookies?.name?.length > 0
                        ? cookies?.name?.charAt(0)
                        : "U"}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem disabled>
                    <Typography textAlign="center">
                      {cookies?.name?.length > 0 ? cookies?.name : "User"}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </span>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  fontWeight: 550,
                  textTransform: "none",
                }}
                onClick={() => {
                  window.location.href = "/login";
                }}
              >
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
