import React from "react";
import IntellirootContainer from "../components/layout-components/Container";
import Navbar from "../components/layout-components/Navbar";
import Footer from "../components/layout-components/Footer";
import SectionOne from "../components/404/SectionOne";

const FourOFour = () => {
  return (
    <React.Fragment>
      <Navbar />
      <IntellirootContainer>
        {/* <BannerText />
        <Books /> */}
        {/* <SwipeableVideo /> */}
        <SectionOne />
      </IntellirootContainer>
      <Footer />
    </React.Fragment>
  );
};

export default FourOFour;
