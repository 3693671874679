const data = [
  {
    id: 1,
    main: "Basic Malware Analysis (Prerequisite)",
    syllabus: [
      {
        title: "Module 1. Introduction (1 hr)",
        description: "The module covers the following:",
        sub: [
          { title: "Malware Analysis in roles require malware analysis" },
          {
            title: "Where is Reverse Engineering required in malware analysis",
          },
          { title: "Lab setup" },
        ],
      },
      {
        title: "Module 2. PE file format (1.5 hours)",
        description:
          "A portable executable has many fields which are required to understand not only for basic analysis but also to understand complex concepts like Hooking. The module covers the following concepts:",
        sub: [
          { title: "PE file creation" },
          {
            title: "PE file static attributes",
          },
          { title: "PE file execution and it's mapping into virtual memory" },
          {
            title:
              "Important concepts  in the PE file like VA, RVA, Entry point, imports, exports",
          },
          {
            title: "Different types of PE files",
          },
        ],
      },
      {
        title: "Module 3. Windows Internal (45 minutes)",
        description:
          "The module gives a superficial idea on Windows Operating system which is required for malware analysis. The module covers the following concepts:",
        sub: [
          { title: "Windows File System" },
          {
            title:
              "Basic Process internals like process virtual memory, handles, thread Thread Context",
          },
          { title: "Windows Registry" },
        ],
      },
      {
        title: "Module 4. Basic Malware Analysis (45  mins)",
        description:
          "This module serves as a rapid review of both static and dynamic analysis methodologies, encompassing critical topics such as virtual memory analysis and API logging often overlooked by analysts. The module covers the following:",
        sub: [
          { title: "Malware Analysis steps" },
          {
            title: "File format identification",
          },
          { title: "Use of procmon" },
          {
            title: "Virtual Memory string extraction",
          },
          {
            title: "API logging",
          },
        ],
      },
      {
        title: "Module 5. Tools manual (2 hours)",
        description:
          "Reverse Engineering necessitates proficiency with a plethora of tools such as Ghidra, IDA, and x64Dbg. This module is dedicated to elucidating the configuration, user interface customization, plugin installation, and essential analysis features of these tools. Serving as a comprehensive reference, it equips learners with the expertise needed to navigate these tools effectively. The module covers the following topics:",
        sub: [
          { title: "Ghidra manual" },
          {
            title: "IDA manual",
          },
          { title: "Ollydbg manual" },
          { title: "X64dbg Manual" },
          { title: "Other tools" },
        ],
      },
      {
        title: "Module 6. Assembly instruction (4 hours)",
        description:
          "This module streamlines the learning of assembly language, addressing a common barrier encountered by aspiring Reverse Engineering enthusiasts. By employing animated demonstrations, it simplifies the comprehension of assembly instructions, alleviating the frustration often associated with extensive documentation. Additionally, customized samples are utilized to present assembly concepts sequentially, enhancing clarity and accessibility for learners. The module covers the following concepts:",
        sub: [
          {
            title:
              "Computer architecture - code execution, instructions, registers ,memory",
          },
          {
            title: "Data movement instruction",
          },
          { title: "String instructions" },
          { title: "Arithmetic instruction" },
          { title: "Control flow instructions" },
          { title: "Functions calls, parameters, stack" },
          { title: "Memory layout of arrays, stacks, local variables" },
        ],
      },
    ],
  },
  {
    id: 2,
    main: "Reverse Engineering Syllabus",
    syllabus: [
      {
        title: "Module 7. Disassembly reading (1 hour)",
        description:
          "The module focuses on reading disassembly and deriving meaning from them. The module covers the following concepts:",
        sub: [
          { title: "Read code listing in disassembler, debugger" },
          { title: "Identify local variables, global variables" },
          { title: "Identify functions, parameters" },
          { title: "Identify array and structures" },
          {
            title:
              "Identify complex data structures like array of structures and linked lists",
          },
        ],
      },
      {
        title:
          "Module 8 - Windows Internals part 1 - Windows API Internals(30 minutes)",
        description:
          "Windows malwares implements various malicious techniques with the help of API’s. As such one needs to understand how to analyze the API’s. Also understanding  of API internals can be helpful to understand how detection techniques are developed and even helpful to understand how malwares evade detection. The module covers the following concepts:",
        sub: [
          { title: "Referencing to API documentation" },
          { title: "How to read API parameters" },
          { title: "Analyze parameters passed as structures" },
          { title: "API variations - Nt versions, Ascii, Unicode" },
          {
            title:
              "Understanding API call flow from user to kernel, Syscall - concept useful in analyzing malwares, EDR bypass techniques, API hooking",
          },
          {
            title:
              "Identifying API sequences in malware code to identify techniques employed by malwares",
          },
        ],
      },
      {
        title:
          "Module 9 - windows Internals - part 2 - Common API sequences in Malwares (1 hour)",
        description:
          "Covers commonly seen API sequences across all kinds of malware. The knowledge of commonly seen API's helps in accelerating Reverse Engineering as they are common across most malwares hence one does not need to reanalyse them. The module covers the following concepts:",
        sub: [
          { title: "Process iteration" },
          { title: "File iteration" },
          { title: "API resolution" },
          { title: "Resource extraction" },
          {
            title: "Registry manipulation",
          },
          {
            title: "Threads",
          },
        ],
      },
      {
        title:
          "Module 10 - windows Internals - part 3 - Debug DLL, COM Objects, Services, PEB(1 hour)",
        description:
          "Learn techniques to debug programs which cannot be debugged straight forward like and exe.The module covers the following concepts:",
        sub: [
          { title: "Debugging DLL" },
          { title: "Debugging COM Objects" },
          { title: "Debugging Windows Services" },
        ],
      },
      {
        title: "Module 11 - Cryptography in Malwares ( 1 hour 15 mins)",
        description:
          "Cryptography is used in malwares to hide it’s own data and more popularly in Ransomware. Understanding of the topic would help you to debug ransomwares. The module covers the following concepts:",
        sub: [
          { title: "Understand and Debug Windows Cryptographic API" },
          { title: "Identify Crypto Algorithms in malwares" },
        ],
      },
      {
        title: "Module 12 - Compilers used by Malwares ( 30 minutes)",
        description:
          "Compilers used by Malwares ( 30 minutes) Compilers play a critical role in generating both legitimate and malicious binaries. They inject specific code into compiled binaries, presenting unique challenges for reverse engineering. With various compilers available for different programming languages, understanding how to reverse engineer binaries compiled with different compilers is essential. This knowledge facilitates the creation of static signatures for malware and helps in identifying compiler-inserted code, ultimately saving reverse engineering time. The module covers the following concepts:",
        sub: [
          { title: "Identify compiler used to write malware" },
          { title: "Identify compiler stub" },
          { title: "Identify main in VC++ binaries" },
        ],
      },
      {
        title: "Module 13 - Networking in Malwares (1 hr 45 mins)",
        description:
          "All kinds of malwares use the network to communicate with the CnC, exfiltrate data to the attacker. The module covers the following concepts:",
        sub: [
          {
            title:
              "Basic concepts like network endians and related API's like htons",
          },
          { title: "Understand socket API's, HTTP API's" },
          { title: "Locating DGA algorithms" },
          { title: "Identify Lateral movement API's" },
        ],
      },
      {
        title: "Module 14- Packers and Unpacking(3 hours)",
        description:
          "Malwares use packers to protect executables from static file detection used in Antivirus. The packer hides the actual functionality of the malware. In order to understand the actual functionality of the packer, one needs to unpack it and extract the actual payload by unpacking. The module teaches various tricks to unpack malware using debugger and other tools. Note: Some of the tricks explained are not available on the internet. The module covers the following concepts:",
        sub: [
          {
            title: "Understand packers and process of packing binaries",
          },
          { title: "steps and code involved in packed binary execution" },
          { title: "unconventional and undocumented ways to unpack malwares" },
          {
            title:
              "systematic way to Reverse packed binaries rather than just putting breakpoint on some API's",
          },
        ],
      },
      {
        title: "Module 15 - Code Injection ( 2 hours)",
        description:
          "Malware use code injection to conceal their actual functionality by injecting code into clean processes. Code injection is further used in concepts like API hooking which in turn is used by rootkits as well. The module covers the following concepts:",
        sub: [
          {
            title:
              "Conventional code injection techniques like shellcode injection and DLL injection",
          },
          {
            title:
              "Advanced techniques like Thread hijacking, section and views, process hollowing",
          },
          {
            title:
              "Learn tricks to debug both the injector as well as injected code",
          },
        ],
      },
      {
        title: "Module 16 -API Hooking   part 1  - ( 1 hour)",
        description:
          "API hooking is a method to modify functionality of a process by manipulating the API in memory. The concept is used by anti-malware softwares as well as malwares. API hooking is used by banking trojans and rootkits. In the module, one would understand the working of hooks and recognize them in disassembly. The module covers the following concepts:",
        sub: [
          {
            title:
              "Type of API hooks inline hooking, IAT hooking and understand it",
          },
          {
            title: "Identifying and Debugging hooks",
          },
        ],
      },
      {
        title: "Module 17 - Rootkits - part 1 - User Mode Rootkit ( 1 hour)",
        description:
          "Malwares use rootkits to hide artifacts like file, processes. Rootkits can be user mode or kernel mode. The module covers working of user mode rootkits and their analysis. The module covers the following concepts:",
        sub: [
          {
            title: "Use of API hooks in rootkits",
          },
          {
            title: "Disassembly analysis of user mode rootkit",
          },
        ],
      },
      {
        title: "Module 18 - Rootkit - part 2 - Kernel Driver-basics (15 mins)",
        description:
          "Kernel mode rootkits are implemented using kernel drivers. The module explains the working of kernel drivers and teaches how to debug them. Only basics are covered which are enough to kickstart analysis of kernel drivers. The module covers the following concepts:",
        sub: [
          {
            title:
              "Basic concepts of windows driver - driver objects, irp's, ioctl's",
          },
        ],
      },
      {
        title: "Module 18 - Rootkit - part 3 - Kernel Debugging ( 1 hour)",
        description:
          "In this module, we would debug a kernel rootkit. The module covers the following concepts:",
        sub: [
          {
            title: "Configuring Windbg for remote kernel debugging",
          },
          {
            title: "Breaking at entry point of kernel drivers",
          },
          {
            title: "SSDT rootkit concept",
          },
          {
            title: "DKOM rootkit concept",
          },
          {
            title: "Debug a DKOM rootkit",
          },
        ],
      },
      {
        title: "Module 19 - .net Reverse Engineering ( 1.5 hours)",
        description:
          "A lot of malwares today are known to use .net. In this module one would learn various approaches to reverse engineer .net malwares. The module covers the following concepts:",
        sub: [
          {
            title: "Basic working and analysis of .net executables",
          },
          {
            title: "Debugging .net binaries",
          },
          {
            title: "Unpacking deobfuscating .net binaries",
          },
        ],
      },
      {
        title: "Module 20 - VB Malware ( 30 mins)",
        description:
          "Malware analysts might often encounter malwares written in Visual basic. In this module one would learn to analyse visual basic binaries. The module covers the following concepts:",
        sub: [
          {
            title: "Basics of visual basic binaries",
          },
          {
            title: "Reverse Engineering visual basic binaries.",
          },
        ],
      },
      {
        title: "Module 21 - Golang Malware RE ( 30 mins)",
        description:
          "There is a growth in malwares written in golang. In this module one would learn to analyse golang compiled binaries. The module covers the following concepts:",
        sub: [
          {
            title: "Basic of golang binaries",
          },
          {
            title: "Reverse Engineering golang binaries",
          },
        ],
      },
      {
        title:
          "Module 22 - Anti-Analysis / Evasion techniques  in malware( 1 hour)",
        description:
          "Malwares use anti-techniques to evade analysis and evade security software like EDR's, Antivirus, Sandboxes. In this module one is going to learn about anti-techniques and how to locate and bypass them. The module covers the following concepts:",
        sub: [
          {
            title: "Anti-VM",
          },
          {
            title: "Anti-Debug",
          },
          {
            title: "Sandbox evasion",
          },
          {
            title: "EDR and endpoint evasion",
          },
        ],
      },
      {
        title: "Module 23  - payload part 1 - Generic Concepts (1.5 hours)",
        description:
          "There are a variety of malwares today which includes ransomware stealers, wipers, POS Malwares, ATM malware, RAT's etc. They employ various techniques like credential harvesting, keylogging, memory scanning etc. The module covers identification of these functionalities in disassembly code. The module can help you to learn working of these techniques which can be used to enhance detection features in anti-malware products. The module covers the following concepts:",
        sub: [
          {
            title: "Identification of and Ransomware techniques",
          },
          {
            title: "Identification and analysis  of keylogging code",
          },
          {
            title:
              "Identification and analysis  of stealer code targeting browsers",
          },
          {
            title:
              "Identification of Code related to taking screenshots, controlling webcam etc",
          },
          {
            title: "Identification of POS stealing code",
          },
        ],
      },
      {
        title: "Module 23 - payload part 2 - Case Studies ( 2 hours approx)",
        description:
          "The module covers case studies of some malware families and debug them. The module covers the following concepts:",
        sub: [
          {
            title: "Debugging of real Ransomware",
          },
          {
            title: "Trick to debug  of real POS malware",
          },
          {
            title: "Analysis of stealer",
          },
        ],
      },
      {
        title:
          "Module 24 - Building Blocks of Malware Detection (1 hour approx)",
        description:
          "The module talks about building blocks of malware detection technologies. Only basic concepts with some reference to open source code are covered. The module would help those who are inspired to learn about the inner workings of antimalware technologies like EDR, Antivirus, sandboxes. Also it would be helping in understanding evasion techniques used to malwares and Red Teamers. The module covers the following concepts:",
        sub: [
          {
            title:
              "Static file scanning - also covers how to optimize static file detection and avoid false positives",
          },
          {
            title: "Behavior detection",
          },
          {
            title: "Event tracing for windows (ETW)",
          },
          {
            title: "Antimalware scan interface (AMSI)",
          },
          {
            title: "Memory scanning",
          },
          {
            title: "Use of API hooking in malware Detection",
          },
        ],
      },
    ],
  },
];

export default data;
