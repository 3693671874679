const data = [
  {
    id: 1,
    title: "Basic Malware Analysis (Prerequisite)",
    description:
      "Before the start of the training students would be provided prerequisite video through our portal Intelliroot academy (academy.intelliroot.com). Prerequisite would cover the following topics. The prerequisite videos are accompanied with hands-on exercises.",
    syllabus: [
      {
        title: "VM setup - look n feel",
      },
      {
        title: "Basic analysis",
        sub: [
          { title: "File format" },
          { title: "Static Analysis" },
          { title: "Dynamic Analysis" },
          { title: "Identify Anomalies" },
          { title: "Section properties executable" },
        ],
      },
      {
        title:
          "PE file,API,  DLL, address Windows Virtual Memory Address space",
        sub: [
          { title: "PE file creation" },
          { title: "PE static properties" },
          { title: "PE Execution and Virtual Memory" },
          { title: "DLL, Imports, Exports" },
        ],
      },
      {
        title: "Windows internals",
        sub: [
          { title: "Process exe" },
          { title: "Handles" },
          { title: "Mutex" },
          { title: "Process path" },
          { title: "Parent child relation" },
          { title: "Process tree" },
          { title: "Session" },
          { title: "Modules" },
          { title: "PEB" },
          { title: "Kernel space and user space concept" },
          { title: "What are Windows API(in context of printf)" },
        ],
      },
      {
        title: "Virtual Memory  Windows Virtual Memory - User and Kernel space",
      },
      {
        title: "Computer architecture and ASM basics",
        sub: [{ title: "Detailed ASM instructions with Labs" }],
      },
      {
        title: "Tool walkthrough",
        sub: [
          { title: "Ollydbg" },
          { title: "X64dbg" },
          { title: "Ghidra" },
          { title: "IDA" },
          { title: "API logger" },
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Reverse Engineering Syllabus",
    description: "",
    syllabus: [
      {
        title: "Day 1- ASM reading",
        sub: [
          {
            title: "Basic instructions overview",
            sub: [{ title: "Hands-on Labs" }],
          },
          {
            title: "Disassembly reading exercises",
            sub: [{ title: "Hands-on Labs" }],
          },
          {
            title: "Identifying functions, local variable, structure array",
            sub: [{ title: "Hands-on Labs" }],
          },
        ],
      },
      {
        title: "Day 2- Win32 API’s",
        sub: [
          {
            title:
              "analyze simple and complex(structures parameters), types of API - Nt, ASCII, Unicode",
            sub: [{ title: "Hands-on Labs" }],
          },
          {
            title: "Common win32 APIs",
            sub: [{ title: "Hands-on Labs + Recorded labs for homework" }],
          },
          {
            title: "Network API’s, -CnC communication Lateral Movement etc",
            sub: [{ title: "Hands-on Labs" }],
          },
        ],
      },
      {
        title: "Day 3",
        sub: [
          {
            title: "Ghidra Use",
            sub: [
              {
                title: "Using decompiler efficiently",
                sub: [{ title: "Hands-on Labs" }],
              },
              {
                title: "Combine debugger and decompiler",
                sub: [{ title: "Hands-on Labs" }],
              },
            ],
          },
          {
            title: "Dealing with variety of Compilers (VC++ , VB, Delphi)",
            sub: [{ title: "Hands-on Labs + Recorded labs for homework" }],
          },
          {
            title: "Algorithm used in Malware",
            sub: [
              { title: "Windows Crypto API" },
              {
                title: "Real Ransomware Debugging",
                sub: [{ title: "Hands-on Labs + Recorded labs for homework" }],
              },
            ],
          },
        ],
      },
      {
        title: "Day 4 - Packer and Unpacking",
        sub: [
          {
            title: "Debugging known packer",
            sub: [{ title: "Hands-on Labs" }],
          },
          {
            title: "Debugging unknown packer(multiple techniques)",
            sub: [{ title: "Hands-on Labs + Recorded labs for homework" }],
          },
        ],
      },
      {
        title: "Day 5 - Code Injection",
        sub: [
          {
            title:
              "Various techniques like DLL injection, Process hollowing, Thread Hijacking etc",
          },
          {
            title: "Hands-on Labs + Recorded labs for homework",
          },
        ],
      },
      {
        title: "Day 6",
        sub: [
          {
            title: "API hooking",
            sub: [
              { title: "Hook type" },
              {
                title: "Banking Malware and Hooks",
              },
              {
                title: "Hands-on Labs Trick Debugging hooks",
              },
            ],
          },
          {
            title: "Rootkits",
            sub: [
              {
                title: "User Mode Rootkit",
                sub: [
                  { title: "File and Process Hiding Rootkits" },
                  {
                    title: "Hands on Labs",
                  },
                ],
              },
              {
                title: "Kernel Rootkit",
                sub: [
                  {
                    title: "Demo Only -(old Windows Rootkits - SSDT, DKOM etc)",
                  },
                ],
              },
            ],
          },
          {
            title: "Malware Evasion/Anti-techniques",
          },
        ],
      },
      {
        title: "Day 7",
        sub: [
          {
            title: ".Net Malware",
            sub: [
              {
                title: "Basics of .net Binaries",
              },
              {
                title: "Deobfuscation",
                sub: [
                  {
                    title: "Hands-on Labs",
                  },
                ],
              },
              {
                title: "Quick Unpacking .net Malwares",
                sub: [
                  {
                    title: "Hands-on Labs",
                  },
                ],
              },
            ],
          },
          {
            title: "Reverse Engineering VB, Golang Binaries",
            sub: [
              {
                title: "VB Binaries",
                sub: [
                  {
                    title: "PCode vs Native",
                  },
                  {
                    title: "Hands-on Labs ",
                  },
                ],
              },
              {
                title: "GoLang Binaries",
                sub: [
                  {
                    title: "Theory",
                  },
                  {
                    title: "Hands-on Labs ",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title:
          "Day 8 - Payload Analysis (Identifying and Analyzing payload functionalities)",
        sub: [
          {
            title: "Revisiting Ransomware",
            sub: [
              {
                title: "Hands-on Labs ",
              },
            ],
          },
          {
            title: "Password stealers",
            sub: [
              {
                title: "Hands-on Labs ",
              },
            ],
          },
        ],
      },
      {
        title: "Day 9 - Extended for discussion",
        sub: [
          {
            title: "Payload Analysis - continued",
            sub: [
              {
                title: "POS malware Analysis",
                sub: [
                  {
                    title: "Hands-on Labs",
                  },
                ],
              },
              {
                title: "Remote Access Trojan",
                sub: [
                  {
                    title: "(keylogging, screenshot, camera etc features)",
                  },
                  {
                    title: "Hands-on Labs",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title:
          "Day 10 - Bonus Topics(Combination of online and Recorded Videos)",
        sub: [
          {
            title:
              "Note: Some topics are still under development and would be updated in the portal",
          },
          {
            title: "Discussion on latest Malwares",
          },
          {
            title: "Ghidra Scripting",
            sub: [
              {
                title: "Basics",
              },
              {
                title: "Headless Module",
              },
              {
                title: "Automating Ghidra",
              },
            ],
          },
          {
            title: "Detection Engineering",
          },
          {
            title: "Antivirus Architecture",
            sub: [
              {
                title: "Components of Antivirus",
              },
              {
                title: "Writing Malware Detection",
              },
              {
                title:
                  "Writing performance oriented signatures for static Detection",
              },
            ],
          },
          {
            title: "Static yara rules",
          },
          {
            title: "EDR architecture and rules",
          },
        ],
      },
    ],
  },
];

export default data;
