import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogContent,
  DialogActions,
  // IconButton,
  Typography,
  Link,
} from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
import data from "./CourseData.js";

const ModalDialog = styled(Dialog)(({ theme }) => ({
  // "& .MuiDialogContent-root": {
  //   padding: 0,
  // },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const TextContainer = styled("div")(({ theme }) => ({
  padding: "0rem 2rem 0rem 1rem",
}));

const LinkedIn = styled(Link)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#fff" : "#000",
  "&:hover": {
    color: "#d32525",
  },
}));

export default function ModuleModal(props) {
  return (
    <ModalDialog
      aria-labelledby="customized-dialog-title"
      open={props.open}
      onClose={props.onClose}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogContent>
        {/* <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
            zIndex: 9999,
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <TextContainer>
          <Typography variant="h4" component="h4">
            {props.title ? props.title : ""}
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              mb: 3,
            }}
          >
            Instructor:
            <LinkedIn
              underline="none"
              target="_blank"
              href="https://www.linkedin.com/in/abhijitmohanta/"
            >
              {" "}
              Abhijit Mohanta
            </LinkedIn>
          </Typography>
          {/* <Typography
            variant="h6"
            component="h6"
            sx={{
              mb: 3,
            }}
          >
            Price: 40,000 INR / 500 USD (excluding taxes)
          </Typography> */}
          {/* <Typography variant="body1" component="span">
            {props.description ? props.description : ""}
          </Typography> */}
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontWeight: "bold",
              mb: 1,
            }}
          >
            A simplified approach to deeply understand and reverse engineer
            windows malware. With lots of exercises on real and simulated
            malware samples, the students would learn to reverse engineer
            diverse techniques employed by malware by utilizing the freely
            available tools like Ghidra, IDA and x64Dbg.
          </Typography>
          <Typography variant="body1" component="p">
            Today all levels of security professionals encounter malware at some
            point of time, hence they need to understand how to analyze malware.
            Reverse engineering is a technique to dig deeply into malware at the
            code level. Reverse Engineering requires understanding of computer
            architecture, Operating systems. No worries in case you are not
            comfortable with assembly language, you are going to learn it in
            this training. The training focuses on Reverse engineering Malware
            on <b>Windows platform</b>. The training is meant for both beginners
            and experienced professionals.
          </Typography>
          <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
            The training has been devised to smoothen the learning process with
            help of simulated and real malware samples. A lot of complex
            concepts like <b>code injection, API hooking</b> have been explained
            with the help of <b>animations</b> so that students can{" "}
            <b>visualize </b>
            the concepts and grasp them quickly. At the same time a lot of lab
            exercises have been included in the training so that students can
            correlate the theory they learnt with the real world scenarios.
          </Typography>
          <Typography variant="body1" component="p" sx={{ mb: 1 }}>
            The training walks you through various diversities in windows
            malware which include malware coded in various programming
            languages, armored with various packers and armoring tricks. The
            training covers various techniques employed by windows malware which
            is not limited to code injection, process hollowing, API, rootkits.
            Students would learn to reverse engineer various kinds of malware
            families which include{" "}
            <b>Ransomware, Stealers, Remote Access Trojans(RATS)</b> written in
            various programming languages from <b>.net, C, C++ </b>etc. The
            training would teach students to effectively use tools like{" "}
            <b>NSA Ghidra, IDA pro, ollydbg, process hacker</b> and man.
          </Typography>
          <Typography variant="body1" component="p" sx={{ mb: 4 }}>
            Other than the course also include bonus topics like Ghidra Scripts,
            writing Detection for Malwares.
          </Typography>

          {data?.map((item, index) => (
            <div key={index}>
              <Typography
                variant="h5"
                component="h5"
                sx={{
                  mt: 3,
                  mb: 1,
                  fontWeight: "bold",
                }}
              >
                {item.title ? item.title : ""}
              </Typography>
              <Typography variant="body1" component="span">
                {item.description ? item.description : ""}
              </Typography>
              {item?.syllabus?.map((main, key) => (
                <ul key={key}>
                  <li>
                    {main.title ? main.title : ""}
                    {main?.sub?.map((sub, k) => (
                      <ul key={k}>
                        <li>{sub.title ? sub.title : ""}</li>
                        {sub?.sub?.map((value, i) => (
                          <ul key={i}>
                            <li>{value.title ? value.title : ""}</li>
                            {value?.sub?.map((v, i) => (
                              <ul key={i}>
                                <li>{v.title ? v.title : ""}</li>
                              </ul>
                            ))}
                          </ul>
                        ))}
                      </ul>
                    ))}
                  </li>
                </ul>
              ))}
            </div>
          ))}
        </TextContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          sx={{
            textTransform: "none",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </ModalDialog>
  );
}
