import React from "react";
import { styled } from "@mui/system";
import { Paper } from "@mui/material";
import { getCookies } from "../../utils/Cookie";
import Preview from "./sub-components/Preview";
import Courses from "../courses/SectionOne";
import Prerequisite from "./sub-components/Prerequisite";
import Labs from "./sub-components/Labs";
import Recordings from "./sub-components/Recordings";
import Detect from "./sub-components/Detect";
import Bonus from "./sub-components/Bonus";

const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "3rem 0",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "row",
  gap: "1rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const LeftContainer = styled(Paper)(({ theme }) => ({
  width: "30%",
  padding: "1.2rem 1.5rem 1.5rem 1.5rem",
  boxShadow: "0 4px 6px -1px rgb(0 0 0 / 20%), 0 2px 4px -1px rgb(0 0 0 / 6%)",
  borderRadius: "0.5rem",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  overflowY: "auto",
  maxHeight: "100vh",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const RightContainer = styled(Paper)(({ theme }) => ({
  width: "70%",
  padding: "1.2rem 1.5rem 1.5rem 1.5rem",
  boxShadow: "0 4px 6px -1px rgb(0 0 0 / 20%), 0 2px 4px -1px rgb(0 0 0 / 6%)",
  borderRadius: "0.5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "0.5rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const LoadingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 5rem)",
}));

function SectionOne() {
  const cookies = getCookies();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [prerequisiteSelect, setPrerequisiteSelect] = React.useState([]);
  const [labsSelect, setLabsSelect] = React.useState([]);
  const [recordingsSelect, setRecordingsSelect] = React.useState([]);
  const [bonusSelect, setBonusSelect] = React.useState([]);
  const [detectSelect, setDetectSelect] = React.useState([]);
  const [selected, setSelected] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetch("https://academy.intelliroot.com/api/trainings/all", {
        cache: "no-store",
        method: "POST",
        headers: {
          Authorization: cookies?.token ? `Bearer ${cookies?.token}` : "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_category: cookies?.user_category,
        }),
      })
        .then((r) => r.json())
        .then((d) => {
          if (d.content !== null && d.statusCode === 200) {
            setData(d.content);
            setPrerequisiteSelect([0, 0]);
            setSelected(
              d?.content?.prerequisite[0]?.sub_modules[0]
                ? d?.content?.prerequisite[0]?.sub_modules[0]
                : d?.content?.prerequisite[0] || null
            );
          }
          setLoading(false);
        })
        .catch((e) => {
          // console.error(e);
          setLoading(false);
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {cookies?.token?.length > 0 ? (
        <div>
          {!loading ? (
            <MainContainer>
              <LeftContainer>
                <Prerequisite
                  data={
                    data?.prerequisite?.length > 0 ? data?.prerequisite : null
                  }
                  selectedIndex={prerequisiteSelect}
                  onSelect={(selected, selectedIndex) => {
                    setPrerequisiteSelect(selectedIndex);
                    setSelected(selected);
                    setLabsSelect([]);
                    setRecordingsSelect([]);
                    setBonusSelect([]);
                    setDetectSelect([]);
                  }}
                />
                <Labs
                  data={data?.Labs?.length > 0 ? data?.Labs : null}
                  selectedIndex={labsSelect}
                  onSelect={(selected, selectedIndex) => {
                    setLabsSelect(selectedIndex);
                    setSelected(selected);
                    setPrerequisiteSelect([]);
                    setRecordingsSelect([]);
                    setBonusSelect([]);
                    setDetectSelect([]);
                  }}
                />
                <Detect
                  data={data?.Detect?.length > 0 ? data?.Detect : null}
                  selectedIndex={detectSelect}
                  onSelect={(selected, selectedIndex) => {
                    setDetectSelect(selectedIndex);
                    setSelected(selected);
                    setPrerequisiteSelect([]);
                    setRecordingsSelect([]);
                    setBonusSelect([]);
                    setLabsSelect([]);
                  }}
                />
                <Bonus
                  data={data?.Bonus?.length > 0 ? data?.Bonus : null}
                  selectedIndex={bonusSelect}
                  onSelect={(selected, selectedIndex) => {
                    setBonusSelect(selectedIndex);
                    setSelected(selected);
                    setPrerequisiteSelect([]);
                    setRecordingsSelect([]);
                    setDetectSelect([]);
                    setLabsSelect([]);
                  }}
                />
                <Recordings
                  data={data?.live?.length > 0 ? data?.live : null}
                  selectedIndex={recordingsSelect}
                  onSelect={(selected, selectedIndex) => {
                    setRecordingsSelect(selectedIndex);
                    setSelected(selected);
                    setPrerequisiteSelect([]);
                    setLabsSelect([]);
                    setBonusSelect([]);
                    setDetectSelect([]);
                  }}
                />
              </LeftContainer>
              <RightContainer>
                {selected === null ? (
                  <LoadingContainer>Select to View</LoadingContainer>
                ) : (
                  <Preview
                    title={selected?.title}
                    subtitle={selected?.subtitle}
                    image={selected?.thumbnail}
                    video={selected?.video_url}
                    description={selected?.description}
                    attachment={selected?.attachment}
                  />
                )}
              </RightContainer>
            </MainContainer>
          ) : (
            <LoadingContainer>Loading...</LoadingContainer>
          )}
        </div>
      ) : (
        <Courses />
      )}
    </React.Fragment>
  );
}

export default SectionOne;
