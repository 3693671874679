import React from "react";
import { styled as muiStyled } from "@mui/system";
import { Typography, Paper, Avatar, Link } from "@mui/material";
import TestimonialModal from "./Modal";

const Container = muiStyled(Paper)(({ theme }) => ({
  width: "27rem",
  height: "auto",
  padding: "1.5rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "1rem",
  borderRadius: "1rem",
  boxShadow: "0 0 10px 0 rgba(100, 100, 100, 0.2)",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Review = muiStyled(Typography)(({ theme }) => ({
  height: "10rem",
  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
}));

const Image = muiStyled(Avatar)(({ theme }) => ({
  width: "3.5rem",
  height: "3.5rem",
}));

const ImageUserContainer = muiStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
}));

const LinkedIn = muiStyled(Link)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#fff" : "#000",
  "&:hover": {
    color: "#d32525",
  },
}));

function TestimonialCard({ name, location, review, image, linkedin }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <TestimonialModal
        open={open}
        onClose={handleClose}
        name={name}
        location={location}
        review={review}
        image={image}
        linkedin={linkedin}
      />
      <Container>
        <Review variant="body1">
          {review?.length > 200 ? (
            <>
              {review?.slice(0, 200)}...
              <Link component="button" onClick={handleOpen}>
                Read More
              </Link>
            </>
          ) : (
            review
          )}
        </Review>

        <ImageUserContainer>
          <Image src={image} />
          <div>
            <LinkedIn underline="none" target="_blank" href={linkedin}>
              <Typography variant="body2">{name}</Typography>
            </LinkedIn>
            <Typography
              variant="body2"
              sx={{
                color: "#757575",
              }}
            >
              {location}
            </Typography>
          </div>
        </ImageUserContainer>
      </Container>
    </React.Fragment>
  );
}

export default TestimonialCard;
