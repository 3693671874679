import React from "react";
import { getCookies } from "../../../utils/Cookie";
import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const Video = styled("iframe")(({ theme }) => ({
  border: "0",
  borderRadius: "0.5rem",
  width: "100%",
  aspectRatio: "16/9",
  marginBottom: "1rem",
}));

const Content = styled("ul")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  padding: "0 0.5rem 0 0.5rem",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  listStyleType: "none",
  "& li::before": {
    content: "'\\2713\\0020'",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    display: "inline-block",
    width: "1.5em",
  },
}));

export default function Preview({
  title,
  subtitle,
  // image,
  description,
  video,
  attachment,
}) {
  const cookies = getCookies();

  const handleDownload = () => {
    const reqURL = encodeURI("https://academy.intelliroot.com/api/file");
    fetch(reqURL, {
      cache: "no-store",
      method: "POST",
      headers: {
        Authorization: cookies?.token ? `Bearer ${cookies?.token}` : "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_names: attachment,
      }),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (data?.statusCode === 200 && data?.content !== null) {
              const { files, notFoundFiles } = data.content;

              if (notFoundFiles.length > 0) {
                console.warn("Some files were not found:", notFoundFiles);
                // Optionally, notify the user about the missing files
              }

              const zip = new JSZip();

              // Loop through the found files and add them to the zip
              files.forEach((file) => {
                const binary = atob(file.fileBuffer); // Decode base64 string
                const arrayBuffer = new Uint8Array(binary.length);
                for (let i = 0; i < binary.length; i++) {
                  arrayBuffer[i] = binary.charCodeAt(i);
                }
                zip.file(file.fileName, arrayBuffer.buffer); // Add file to zip
              });

              // Generate and download the zip file
              zip.generateAsync({ type: "blob" }).then((content) => {
                saveAs(content, "attachments.zip");
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <Video
        title="play video"
        src={video ? video : ""}
        allowFullScreen={true}
        allow="encrypted-media"
      />

      <Typography
        variant="h5"
        style={{
          fontWeight: "600",
        }}
      >
        {title ? title : ""}
      </Typography>
      <Typography variant="body2">{subtitle ? subtitle : ""}</Typography>
      <Content>
        {description?.length > 0
          ? description?.map((d, key) => {
              return (
                <li
                  key={key}
                  style={{
                    padding: "0.25rem 0rem",
                  }}
                >
                  {d ? d : ""}
                </li>
              );
            })
          : null}
      </Content>
      {attachment?.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            marginTop: "1rem",
          }}
          onClick={handleDownload}
        >
          Download
        </Button>
      )}
    </React.Fragment>
  );
}
