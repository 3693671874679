import React from "react";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { styled } from "@mui/system";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  // Link,
  Paper,
  Box,
  Grid,
  Typography,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const SectionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
}));

export default function SignInSide() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [message, setMessage] = React.useState(
    "Please enter your email address and password"
  );

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // cookies encryption
  const encryptData = (data) => {
    if (data !== undefined) {
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        process.env.REACT_APP_CK_KEY
      ).toString();
      return encryptedData;
    }
  };

  // cookies expiration
  const expirationTime = new Date();
  expirationTime.setTime(expirationTime.getTime() + 23 * 60 * 60 * 1000);

  const submitLogin = () => {
    const reqURL = encodeURI("https://academy.intelliroot.com/api/user/login");
    fetch(reqURL, {
      cache: "no-store",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (data?.statusCode === 200 && data?.content !== null) {
              setMessage(data?.message);
              const userData = {
                name: data.content.username,
                user_category: data.content.user_category,
                token: data.content.token,
                // loginTime: new Date().getTime(),
              };
              const encryptedData = encryptData(userData);
              Cookies.set("a7de0d3d", encryptedData, {
                // secure: true,
                // httpOnly: true,
                // sameSite: "strict",
                expires: expirationTime,
                path: "/",
              });
              window.location.href = "/";
            } else {
              setMessage(data?.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateFields = () => {
    if (email === "") {
      setMessage("Please enter your email address");
    } else if (password === "") {
      setMessage("Please enter your password");
    } else {
      setMessage("Logging in...");
      submitLogin();
    }
  };

  return (
    <SectionContainer>
      <Grid container component="main">
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(/images/login/login.webp)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <LockOutlinedIcon
                sx={{
                  color: "white",
                }}
              />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box component="form" sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="email"
                label="Email Address"
                id="email"
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="password">Password *</InputLabel>
                <OutlinedInput
                  id="password"
                  required
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <Typography
                variant="body2"
                textAlign="center"
                mt={2}
                sx={{ color: "#757575" }}
              >
                {message}
              </Typography>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={validateFields}
              >
                Login
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="/reset-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/sign-up" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </SectionContainer>
  );
}
