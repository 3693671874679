import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function StickyFooter() {
  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        px: 2,
        // mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="body1">
        Copyright © Intelliroot Academy 2024
      </Typography>
    </Box>
  );
}
