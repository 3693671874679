import React from "react";
import { styled } from "@mui/system";
import {
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const MainContainer = styled("div")(({ theme }) => ({
  "& .MuiList-root": {
    maxWidth: "100%",
  },
}));

const HeadingText = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  lineHeight: "2rem",
  margin: "2rem 0 1rem 0",
  fontWeight: "600",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
  },
}));

function Bonus(props) {
  const [openItems, setOpenItems] = React.useState([]);

  const handleClick = (key) => {
    setOpenItems((prev) => {
      const temp = [...prev];
      temp[key] = !temp[key];
      return temp;
    });
  };

  return (
    <React.Fragment>
      <MainContainer>
        {props.data?.length > 0 && (
          <div>
            <HeadingText variant="h1">Bonus Topics</HeadingText>
            {props.data?.length > 0
              ? props.data?.map((item, key) => (
                  <List
                    key={key}
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      // bgcolor: "background.paper",
                      padding: "0",
                    }}
                    component="nav"
                    aria-labelledby="courses"
                  >
                    {item?.sub_modules?.length > 0 ? (
                      <div>
                        <ListItemButton
                          sx={{
                            color: props.selectedIndex[0] === key && "#d32525",
                          }}
                          onClick={() => {
                            handleClick(key);
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: "30px",
                            }}
                          >
                            <DoubleArrowIcon
                              sx={{
                                color:
                                  props.selectedIndex[0] === key && "#d32525",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={item.title ? item.title : ""}
                          />
                          {openItems[key] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        {item?.sub_modules &&
                          item?.sub_modules?.map((subItem, subKey) => (
                            <Collapse
                              key={subKey}
                              in={openItems[key]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                <ListItemButton
                                  sx={{
                                    pl: 4,
                                    color:
                                      props.selectedIndex[0] === key &&
                                      props.selectedIndex[1] === subKey &&
                                      "#d32525",
                                  }}
                                  onClick={() => {
                                    props.onSelect(subItem, [key, subKey]);
                                    //   setSelected(subItem);

                                    window.scrollTo(0, 0);
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: "30px",
                                    }}
                                  >
                                    <ArrowRightIcon
                                      sx={{
                                        color:
                                          props.selectedIndex[0] === key &&
                                          props.selectedIndex[1] === subKey &&
                                          "#d32525",
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    primaryTypographyProps={{
                                      fontSize: "0.975rem",
                                    }}
                                    primary={subItem.title ? subItem.title : ""}
                                  />
                                  {subItem.sub_modules.length > 0 ? (
                                    openItems[key] ? (
                                      <ExpandLess />
                                    ) : (
                                      <ExpandMore />
                                    )
                                  ) : null}
                                </ListItemButton>
                                {subItem?.sub_modules?.map(
                                  (subSubItem, subSubKey) => (
                                    <Collapse
                                      key={subSubKey}
                                      in={
                                        openItems[key] &&
                                        props.selectedIndex[1] === subKey
                                      }
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <List component="div" disablePadding>
                                        <ListItemButton
                                          sx={{
                                            pl: 6,
                                            color:
                                              props.selectedIndex[0] === key &&
                                              props.selectedIndex[1] ===
                                                subKey &&
                                              props.selectedIndex[2] ===
                                                subSubKey &&
                                              "#d32525",
                                          }}
                                          onClick={() => {
                                            props.onSelect(subSubItem, [
                                              key,
                                              subKey,
                                              subSubKey,
                                            ]);
                                            //   setSelected(subSubItem);
                                            window.scrollTo(0, 0);
                                          }}
                                        >
                                          <ListItemIcon
                                            sx={{
                                              minWidth: "30px",
                                            }}
                                          >
                                            <FiberManualRecordIcon
                                              sx={{
                                                fontSize: "0.5rem",
                                                color:
                                                  props.selectedIndex[0] ===
                                                    key &&
                                                  props.selectedIndex[1] ===
                                                    subKey &&
                                                  props.selectedIndex[2] ===
                                                    subSubKey &&
                                                  "#d32525",
                                              }}
                                            />
                                          </ListItemIcon>
                                          <ListItemText
                                            primaryTypographyProps={{
                                              fontSize: "0.875rem",
                                            }}
                                            primary={
                                              subSubItem.title
                                                ? subSubItem.title
                                                : ""
                                            }
                                          />
                                        </ListItemButton>
                                      </List>
                                    </Collapse>
                                  )
                                )}
                              </List>
                            </Collapse>
                          ))}
                      </div>
                    ) : (
                      <ListItemButton
                        sx={{
                          color: props.selectedIndex[0] === key && "#d32525",
                        }}
                        onClick={() => {
                          // setSelected(item);
                          props.onSelect(item, [key, 0]);

                          window.scrollTo(0, 0);
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "30px",
                          }}
                        >
                          <DoubleArrowIcon
                            sx={{
                              color:
                                props.selectedIndex[0] === key && "#d32525",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={item.title ? item.title : ""} />
                      </ListItemButton>
                    )}
                  </List>
                ))
              : null}
          </div>
        )}
      </MainContainer>
    </React.Fragment>
  );
}

export default Bonus;
