import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

// Remove cookie
export const removeCookies = () => {
  Cookies.remove("a7de0d3d", {
    path: "/",
    // domain: ".invincione.in",
    expires: new Date(0),
  });
  window.location.href = "/";
};

// Decrypt Cookies
export const decryptData = (data) => {
  try {
    if (data !== undefined) {
      const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_CK_KEY);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    }
  } catch (error) {
    if (error.message === "Malformed UTF-8 data") {
      removeCookies();
      return null;
    } else {
      return null;
    }
  }
};

// Get Cookies
export const getCookies = () => {
  const encryptedUserData = Cookies.get("a7de0d3d");
  return decryptData(encryptedUserData) || null;
};
