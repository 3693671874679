import React from "react";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";

const Container = styled("div")(({ theme }) => ({
  height: "calc(100vh - 7rem)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
}));

const HeadingText = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    fontSize: "2rem",
  },
}));

function SectionOne() {
  return (
    <React.Fragment>
      <Container>
        <HeadingText>Page Not Found</HeadingText>
      </Container>
    </React.Fragment>
  );
}

export default SectionOne;
