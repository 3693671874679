import React from "react";
import { styled as muiStyled } from "@mui/system";
import { Typography, Fab } from "@mui/material";
import TestimonialCard from "./sub-components/TestimonialCard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const HeadingText = muiStyled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 600,
  margin: "2rem 0",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    fontSize: "1.2rem",
  },
}));

const CardsContainer = muiStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "2rem",
  margin: "2rem 0 1rem 0",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const FABContainer = muiStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "1rem",
  marginBottom: "4rem",
}));

function Testimonial() {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetch("https://academy.intelliroot.com/api/testimonials/all", {
        cache: "no-store",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((r) => r.json())
        .then((d) => {
          if (d.content !== null && d.statusCode === 200) {
            setData(d.content);
          }
          setLoading(false);
        })
        .catch((e) => {
          // console.error(e);
          setLoading(false);
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => prevIndex - 3);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => prevIndex + 3);
  };

  return (
    <React.Fragment>
      <HeadingText>Testimonials</HeadingText>
      <div
        style={{
          marginBottom: "4rem",
        }}
      >
        {!loading ? (
          <div>
            {data !== null ? (
              <React.Fragment>
                <CardsContainer>
                  {data
                    .slice(currentIndex, currentIndex + 3)
                    .map((testimonial, index) => (
                      <TestimonialCard
                        key={index}
                        name={testimonial.name ? testimonial.name : "User"}
                        location={
                          testimonial.location
                            ? testimonial.location
                            : "Location"
                        }
                        image={testimonial.image ? testimonial.image : ""}
                        linkedin={
                          testimonial.linkedin ? testimonial.linkedin : ""
                        }
                        review={
                          testimonial.review ? testimonial.review : "No review"
                        }
                      />
                    ))}
                </CardsContainer>
                <FABContainer>
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="swipe-left"
                    onClick={handlePrevClick}
                    disabled={currentIndex === 0}
                  >
                    <ChevronLeftIcon />
                  </Fab>

                  <Fab
                    size="small"
                    color="primary"
                    aria-label="swipe-right"
                    onClick={handleNextClick}
                    disabled={currentIndex >= data.length - 3}
                  >
                    <ChevronRightIcon />
                  </Fab>
                </FABContainer>
              </React.Fragment>
            ) : (
              <Typography variant="body1">No Testimonials</Typography>
            )}
          </div>
        ) : (
          <Typography variant="body1">Loading...</Typography>
        )}
      </div>
    </React.Fragment>
  );
}

export default Testimonial;
